
.homeWrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.stepItem {
    display: flex;
    justify-content: space-between;
}
.am-steps-item-title{
    width: 100%;
}
.applyWrapper{
    padding: 10px;
}
.applyItemWrapper{
    margin-top: 10px;
    padding: 8px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 4px 4px 10px #dcdcdc;
}

.applyItemWrapper .header{
    display: flex;
    justify-content: space-between;

}
.approveWrapper{
    padding: 10px;
}
.approveWrapper .divider{
    width: 100%;
    position: relative;
    margin: 20px 0;
}
.approveWrapper .divider .line{
    height: 1px;
    background-color: black;
}
.approveWrapper .divider span{
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #f5f5f9;
    padding: 0 10px;
    transform: translateX(-50%) translateY(-50%);
}
.pageWrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
}

.addApplyWrapper .content{
    flex: 1;
    overflow-y: auto;
}
.left p{
    margin: 12px auto;
}
.right{
    font-size: 16px;
    font-weight: 550;
}
.userinfo .spe {
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.userinfo .am-result .am-result-pic {
    width: 80px;
    height: 80px;
}
.userinfo .am-result {
    border-bottom:none;
}
.userinfo .am-list-item .am-list-line .am-list-extra {
    flex-basis:80%
}
.info-list .am-list-body{
    border-top:none;
    border-bottom:none;
}
.radio-inline {
    padding: 5px;
    line-height: 20px;
}
.my-radio {

    padding: 2.5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 5px;
}
input[type="radio"] + label::before 
		{
			content: "\a0";  /*不换行空格*/
			display: inline-block;
			vertical-align: .2em;
			width: 15px;
			height: 15px;
			margin-right: 5px;
			border-radius: 50%;
            border-color: silver;
            border-width: 1px;
            border-style: solid;
			text-indent: .15em;
			line-height: .85;  /*行高不加单位，子元素将继承数字乘以自身字体尺寸而非父元素行高*/
		}
		input[type="radio"]:checked + label::before 
		{
			content: "\2713";
			background-color: rgb(185, 219, 250);
		}
		input[type="radio"]
		{
            width: 15px;
            height: 15px;
			position: absolute;
			clip: rect(0, 0, 0, 0);
		}
		input[type="radio"]:focus + label::before 
		{
			box-shadow: 0 0 .1em .1em rgb(198, 225, 255);
		}
		input[type="radio"]:disabled + label::before 
		{
			background-color: gray;
			box-shadow: none;
			color: #555;
		}